import React from "react"
import "../global.css"
import { graphql, useStaticQuery } from "gatsby"
import HeadMetadata from "../components/HeadMetadata"
import HeroDesarrollos from "../desarrollos/HeroDesarrollos"
import Layout from "../layout/Layout"
import CallToAction from '../layout/CallToActionC'
import Img from "gatsby-image"
import logoCarboneras from '../images/logoPuntaMayaBig.png'

export default () => {
  const img = useStaticQuery(graphql`
    {
      fondoHero: file(relativePath: { eq: "pm1.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1800, quality:90) { ...GatsbyImageSharpFluid } } }
      img2: file(relativePath: { eq: "pm2.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
      img3: file(relativePath: { eq: "pm3.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth:1800, quality:90) { ...GatsbyImageSharpFluid } } }
      img4: file(relativePath: { eq: "pm4.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img5: file(relativePath: { eq: "pm5.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img6: file(relativePath: { eq: "pm6.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }

        img2Res: file(relativePath: { eq: "pm2Res.jpg" }) { 
          sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
        img3Res: file(relativePath: { eq: "pm3Res.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth:1800, quality:90) { ...GatsbyImageSharpFluid } } }
        img4Res: file(relativePath: { eq: "pm4Res.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
        img5Res: file(relativePath: { eq: "pm5Res.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
        img6Res: file(relativePath: { eq: "pm6Res.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
       
          
    }
  `)

  return (
    <Layout className="overflow-hidden">
      <HeadMetadata title="Desarrollo Punta Maya - Grupo Libera" />      
      <HeroDesarrollos fondo={img.fondoHero.sharp.fluid} logo={logoCarboneras}   tagline="TERRENOS DE INVERSIÓN PREMIUM" />     
      
      <section>
          <Img fluid={img.img2.sharp.fluid}  className="hidden lg:block" /> 
          <Img fluid={img.img2Res.sharp.fluid}  className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img3.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img3Res.sharp.fluid}  className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img4.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img4Res.sharp.fluid}  className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img5.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img5Res.sharp.fluid}  className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img6.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img6Res.sharp.fluid}  className="lg:hidden" />
      </section>
      

      <div className="bg-black items-center grid">
        <iframe frameborder="no" height="auto" scrolling="auto"
          src="https://crmglibera.com/sistemas_active/crm/if/indexBase.php?clvde=vF78V%20qcdtD53"
          className="m-auto manivela">
        </iframe>
      </div>


      <CallToAction
        phone="tel:9993462300"
        whatsapp="https://wa.me/+529993462300"
        email="mailto:info@grupolibera.mx"
      />

    </Layout>
  )
}
